$mobileBreakPoint: 599px;
$scrollBlockBreakPoint: 1024px;
$headerHeight: 100px;
$headerHeightMobile: 70px;
$breadCrumbsHeight: 40px;
$footerHeight: 40px;
$mainMinHeight: calc(100vh - #{$headerHeight} - #{$breadCrumbsHeight} - #{$footerHeight});
$mainMinHeightMobile: calc(100vh - #{$headerHeightMobile} - #{$breadCrumbsHeight} - #{$footerHeight});
$mainPaddingDesktop: 40px;
$mainPaddingMobile: 15px;
$maxAppWidth: 1440px;
$borderRadius: 15px;
$grey: #aaa8a8;
$lightGrey: rgb(217, 217, 217);

@mixin animation {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
