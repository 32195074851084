@import '../../GlobalVars.scss';

.header {   
    
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../../images/header_background.jpg");
    background-size: auto 100%;

    height: $headerHeight;
    @media screen and (max-width: $mobileBreakPoint) {
        height: $headerHeightMobile;
    }

    &__content { 
        max-width: $maxAppWidth;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        padding-left: $mainPaddingDesktop;
        padding-right: $mainPaddingDesktop;
        height: $headerHeight;
        @media screen and (max-width: $mobileBreakPoint) {
            padding-left: $mainPaddingMobile;
            padding-right: $mainPaddingMobile;
            height: $headerHeightMobile;
        }       
    }

    &__logo {
        color: white;

        &-text {
            text-align: center;
            margin: 0;            
        }

        &-title {
            margin: 0;
            color: white;
            line-height: 1;
            text-transform: uppercase;  
            text-align: center;          
            font-weight: bold;
            font-size: 30px;
            @media screen and (max-width: $mobileBreakPoint) {
                font-size: 20px;
            }
        }

        a {
            color: white;
        }
    }

    &__menu-toggle {        
        color: white;
        cursor: pointer;
        position: absolute;
        left: $mainPaddingDesktop;
        font-size: 24px;
        @media screen and (max-width: $mobileBreakPoint) {
            left: $mainPaddingMobile;
            font-size: 20px;
        }
    }
}