@import "~antd/dist/antd.css";
@import './GlobalVars.scss';

body {
    background-color: #f0f2f5;
    overflow-y: scroll;
}

.App {
    position: relative;
}
