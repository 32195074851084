@import "../../GlobalVars.scss";

.main-page {
    &__about-site {
        position: relative;   
        max-height: 220px;
        overflow-y: hidden;
        transition: max-height 0.3s ease-out;

        &.about-open {
            max-height: 1500px;
            transition: max-height 0.3s ease-out;
        }

        &:not(.about-open):after {
            content: '';
            width: 100%;
            height: 80px;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 90%);
        }

        p {
            text-align: justify;
            @media screen and (max-width: $mobileBreakPoint) {
                text-align: left;
            }
        }
    }

    &__about-site-toggle {
        cursor: pointer;
        text-align: end !important;
        color: grey;
        @media screen and (max-width: $mobileBreakPoint) {            
            text-align: center !important;
            color: rgba(0, 0, 0, 0.85);
        }
        &:hover {
            color: rgba(0, 0, 0, 0.85);
        }
    }

    &__cards {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
}
