$sliderHeightBig: 590px;
$sliderHeightMedium: 500px;
$sliderHeightSmall: 390px;

.achievement-slider-wrapper {
  position: relative;
  height: $sliderHeightBig;

  @media screen and (max-width: 1152px) and (min-width: 1024px) {
    height: $sliderHeightMedium;
  }

  @media screen and (max-width: 420px) {
    height: 390px;
  }
}

.achievement-slider {
  width: 100%;
  height: $sliderHeightBig;

  @media screen and (max-width: 1152px) and (min-width: 1024px) {
    height: $sliderHeightMedium;
  }

  @media screen and (max-width: 420px) {
    height: $sliderHeightSmall;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  .ant-image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-img-wrapper {
      max-width: 50%;
      width: auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: grayscale(100%);
      transition-property: height, width, max-width, max-height, filter;
      transition-duration: 0.3s;
      @media screen and (max-width: 1023px) {
        max-width: 100%;
        filter: none;
      }
      @media screen and (max-width: 420px) {
        max-height: 100%;
      }
    }

    &.swiper-slide-active {
      cursor: zoom-in;

      .slide-img-wrapper {
        max-width: 100%;
        filter: none;
        @media screen and (max-width: 1152px) and (min-width: 1024px) {
          max-height: 100%;
        }
        @media screen and (max-width: 420px) {
          max-height: 100%;
        }
      }
    }
  }

  .swiper-wrapper {
    height: 87%;
  }

  .swiper-pagination {
    bottom: 30px;
    @media screen and (max-width: 420px) {
      bottom: 5px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 43%;
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
}
