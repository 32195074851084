@import "../../GlobalVars.scss";
$animationDuration: 0.3s;
$menuShifTDueToScrollBar: 9px;
$mobileBreakPointShifTDueToScrollBar: 15px;

.menu {
    position: absolute;
    width: 256px;
    overflow: auto;
    max-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    border-radius: $borderRadius;
    z-index: 12;
    left: $mainPaddingDesktop;
    @media screen and (max-width: $mobileBreakPoint) {
        max-height: calc(100vh - #{$headerHeightMobile} - #{$footerHeight});
        left: $mainPaddingMobile;
    }
    @media screen and (min-width: calc(#{$maxAppWidth} + #{$mobileBreakPointShifTDueToScrollBar})) {
        left: calc((100vw - #{$maxAppWidth} + #{$mainPaddingDesktop} * 2) / 2 - #{$menuShifTDueToScrollBar});
    }
}

.overlay {
    z-index: 11;
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: calc(100% - #{$headerHeight});
    @media screen and (max-width: $mobileBreakPoint) {
        height: calc(100% - #{$headerHeightMobile});
    }
    opacity: 1;
    transition: opacity $animationDuration, visibility 0s;

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition: opacity $animationDuration, visibility 0s $animationDuration;
    }
}

body {
    &.menu-is-open {
        @media screen and (max-width: $scrollBlockBreakPoint) {
            overflow-y: hidden;
        }
    }
}
