@import "../../GlobalVars.scss";

$greyBorder: solid 1px $grey;
$lightGreyBorder: solid 1px $lightGrey;

.course-card {
    text-align: center;
    max-width: 300px;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    margin-bottom: 20px;
    background-size: 100%;
    background-position: center;
    overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 7.5px;    
    margin-right: 7.5px;    

    &__title {
        font-weight: bold;
        margin-bottom: 0 !important;
        min-height: 60px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
            url("../../images/card_background.jpg");
        background-size: auto 100%;
        color: white;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    &__description {
        margin-bottom: 0;
        padding: 12px 10px 15px 10px;
        border-bottom: $lightGreyBorder;
        border-left: $lightGreyBorder;
        border-right: $lightGreyBorder;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;

        color: rgba(0, 0, 0, 0.85);

        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}

.course-card:hover {
    .course-card__description {
        border-bottom: $greyBorder;
        border-left: $greyBorder;
        border-right: $greyBorder;
   
    }
}
