@import "../../GlobalVars.scss";

.main {
    max-width: $maxAppWidth;
    margin-left: auto;
    margin-right: auto;

    padding-left: $mainPaddingDesktop;
    padding-right: $mainPaddingDesktop;
    @media screen and (max-width: $mobileBreakPoint) {
        padding-left: $mainPaddingMobile;
        padding-right: $mainPaddingMobile;
    }

    &__content {
        background-color: white;
        padding: 20px;
        position: relative;
        border-radius: $borderRadius;
        min-height: $mainMinHeight;
        @media screen and (max-width: $mobileBreakPoint) {
            min-height: $mainMinHeightMobile;
        }
    }

    &__bread-crumbs-wrapper {
        height: $breadCrumbsHeight;
        display: flex;
        align-items: center;
    }

    h2 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }

    ul {
        padding-left: 17px;
    }

    ol {
        padding-left: 25px;
    }

    li {
        margin-bottom: 7px;
        text-align: justify;
        @media screen and (max-width: $mobileBreakPoint) {
            text-align: left;
        }
    }

    p {
        margin-bottom: 0;        
    }    
}
